import { cryptoWallet, Marketplace } from '@tradist/blockchain/frontend';
import { useContracts } from '../composables/useContracts';

export class MarketplaceService {
	static instance: MarketplaceService;

	marketplace: Marketplace;

	constructor(marketplaceContractAddress) {
		this.marketplace = new Marketplace(
			marketplaceContractAddress,
			cryptoWallet.signer,
		);
	}

	static async getInstance() {
		const { marketplaceContractAddress, initializeContracts } = useContracts();
		if (!this.instance) {
			marketplaceContractAddress.value || (await initializeContracts());
			this.instance = new MarketplaceService(marketplaceContractAddress.value);
		}
		return this.instance;
	}

	async mintToken(
		contractOnChainId: number,
		tokenName: string,
		tokenUri: string,
		attributes: { key: string; value: string }[] = [],
		wait = true,
	) {
		const attributeKeys = attributes.map((a) => a.key) || [];
		const attributeValues = attributes.map((a) => a.value) || [];
		const txn = await this.marketplace.mint(
			tokenName,
			attributeKeys,
			attributeValues,
			tokenUri,
			contractOnChainId,
		);
		if (wait) {
			await txn.wait();
		}
	}

	async listToken(
		tokenId: string,
		contractId: string,
		price: string,
		wait = true,
	) {
		const txn = await this.marketplace.listToken(tokenId, contractId, price);
		if (wait) {
			await txn.wait();
		}
	}

	async buyToken(listingId: string, price: string, wait = true) {
		const txn = await this.marketplace.buyToken(listingId, {
			value: price,
		});
		if (wait) {
			await txn.wait();
		}
	}
}
