
import { defineComponent, nextTick, ref } from 'vue';
import BaseButton from '../components/Base/BaseButton.vue';
import HollowXIcon from '../components/Icons/HollowXIcon.vue';
import BaseSelect from '../components/Base/BaseSelect.vue';
import BaseSelectItem from '../components/Base/BaseSelectItem.vue';
import TagButton from '../components/Buttons/TagButton.vue';
import MaterialInputWithLabel from '../components/Groups/MaterialInputWithLabel.vue';
import BaseTextarea from '../components/Base/BaseTextarea.vue';
import VueNumberInput from '@chenfengyuan/vue-number-input';
import BaseInput from '../components/Base/BaseInput.vue';
import XIcon from '../components/Icons/XIcon.vue';
import BaseCheckbox from '../components/Base/BaseCheckbox.vue';
import { apiService } from '../services/ApiService';
import { cryptoWallet } from '@tradist/blockchain/frontend';
import { MarketplaceService } from '../services/MarketplaceService';
import { IAttribute, INFTMetadata, ITag } from '../types/NFTMetadata';
import { useLoader } from '../composables/useLoader';
import { useContracts } from '../composables/useContracts';
import { config } from '../config';
import BaseToaster from '../components/Base/BaseToaster.vue';
import { useUser } from '../composables/useUser';
import { useRouter } from 'vue-router';

export default defineComponent({
	components: {
		BaseButton,
		HollowXIcon,
		BaseSelect,
		BaseSelectItem,
		TagButton,
		MaterialInputWithLabel,
		BaseTextarea,
		VueNumberInput,
		BaseInput,
		XIcon,
		BaseCheckbox,
		BaseToaster,
	},
	setup() {
		const { openLoader, closeLoader, changeMessage } = useLoader();

		const { poolContract } = useContracts();

		const { updateBalance } = useUser();
		const router = useRouter();

		const isButtonDisabled = ref(true);
		let marketplaceService: MarketplaceService;

		(async function initMarketplace() {
			isButtonDisabled.value = true;
			marketplaceService = await MarketplaceService.getInstance();
			isButtonDisabled.value = false;
		})();

		const tags = ref<ITag[]>([]);
		const toaster = ref({
			visible: false,
			text: '',
			buttonText: '',
			type: '',
			action: () => (toaster.value.visible = false),
		});

		const emptyAttribute: IAttribute = { key: '', value: '' };

		const formState = ref({
			title: '',
			description: '',
			attributes: [{ ...emptyAttribute }],
			tags: [],
			price: '',
			listNFTImmediately: false,
		});

		const handleSelectedTags = (item) => {
			formState.value.tags.push(item);
		};

		const handleUnselectedTags = (item) => {
			const index = formState.value.tags.findIndex((tag) => {
				return tag.id === item.id && tag.value === item.value;
			});
			formState.value.tags.splice(index, 1);
		};

		const handleSelectedUnregisteredTag = (tag) => {
			if (tag) {
				const newTag = {
					id: -1,
					value: tag.trim(),
				};
				tags.value.push(newTag);
				formState.value.tags.push(newTag);
			}
		};

		const handleTagRemove = (index) => {
			index === -1 || formState.value.tags.splice(index, 1);
		};

		const addAttribute = () => {
			formState.value.attributes.push({ ...emptyAttribute });
		};

		const focusAndAddAttribute = (index) => {
			addAttribute();
			nextTick(() => {
				const indexOffset = 3; // 1 for label, 1 for 1 indexed list and 1 for addition
				(
					document.querySelector(
						`.attribute-wrapper:nth-child(${
							index + indexOffset
						}) > .material-input`,
					) as HTMLInputElement
				).focus();
			});
		};

		const removeAttribute = (index) => {
			formState.value.attributes.splice(index, 1);
		};

		const previewImage = ref({
			url: '',
			alt: '',
		});

		const uploadedImage = ref(undefined);

		const handlePreventDefaultDrag = (event) => {
			event.preventDefault();
			event.stopPropagation();
		};

		const handlePreviewImage = async (files) => {
			uploadedImage.value = files[0];
			previewImage.value.url = URL.createObjectURL(files[0]);
			// const { id } = await uploadAsset();
			// assetId.value = id;
		};

		const handleFileUploadByClick = (event) => {
			handlePreviewImage(event.target.files);
		};

		const handleFileDrop = (event) => {
			event.preventDefault();
			event.stopPropagation();
			handlePreviewImage(event.dataTransfer.files);
		};

		const validator = (attrs) => {
			const validateForm =
				formState.value.title !== '' &&
				formState.value.description !== '' &&
				uploadedImage.value;
			if (validateForm) {
				const validateAttributes = attrs.some((attr) => {
					return attr.key === '' || attr.value === '';
				});
				console.log(validateAttributes);
				if (validateAttributes) {
					throw 'Please fill all your attributes keys and values.';
				}
				return true;
			} else {
				throw 'Please add an image, title and description.';
			}
		};

		const handleCreate = async () => {
			isButtonDisabled.value = true;
			const attrs = formState.value.attributes.filter((attr) => {
				return attr.key !== '' || attr.value !== '';
			});
			try {
				validator(attrs);
				openLoader('PREPARING YOUR NFT...');
				const payload: INFTMetadata = {
					minter: cryptoWallet.address,
					minted: false,
					owner: cryptoWallet.address,
					onSale: false,
					title: formState.value.title,
					description: formState.value.description,
					attributes: attrs,
					tags: formState.value.tags,
					assetFile: uploadedImage.value,
					contractAddress: poolContract.value.onChainAddress,
				};

				const { data } = (await apiService.initializeNFTMetadata(payload)) as {
					data: { tokenUri: string };
				};

				changeMessage('MINTING YOUR NFT...');

				await marketplaceService.mintToken(
					poolContract.value.onChainId,
					payload.title,
					data.tokenUri,
					payload.attributes,
				);
				updateBalance();
				toaster.value = {
					visible: true,
					text: 'You have succesfully minted your NFT!',
					buttonText: 'Close',
					type: 'success',
					action: () => (toaster.value.visible = false),
				};
				router.push('/my-items');
			} catch (error: unknown) {
				const errorMessage =
					typeof error === 'string'
						? error
						: 'Some error occured while minting your NFT';
				toaster.value = {
					visible: true,
					text: errorMessage,
					buttonText: 'Close',
					type: 'danger',
					action: () => (toaster.value.visible = false),
				};
				closeLoader();
			} finally {
				isButtonDisabled.value = false;
			}
		};

		return {
			config,
			tags,
			formState,
			handleSelectedTags,
			handleUnselectedTags,
			handleSelectedUnregisteredTag,
			handleTagRemove,
			addAttribute,
			focusAndAddAttribute,
			removeAttribute,
			previewImage,
			handlePreventDefaultDrag,
			handleFileUploadByClick,
			handleFileDrop,
			handleCreate,
			isButtonDisabled,
			toaster,
		};
	},
});
