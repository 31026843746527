
import { computed, defineComponent } from 'vue';

export default defineComponent({
	name: 'BaseTextarea',
	props: {
		modelValue: {
			type: String,
			required: true,
		},
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const inputValue = computed<string>({
			get() {
				return props.modelValue;
			},
			set(val) {
				emit('update:modelValue', val);
			},
		});

		return {
			inputValue,
		};
	},
});
