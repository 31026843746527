import type { CollectionContract } from '@prisma/client';
import { Ref, ref } from 'vue';
import { apiService } from '../services/ApiService';

interface IContract {
	marketplace: string;
	collectionContracts: CollectionContract[];
}

interface IUseContractReturn {
	initializeContracts: () => void;
	contracts: Ref<CollectionContract[]>;
	marketplaceContractAddress: Ref<IContract['marketplace']>;
	poolContract: Ref<CollectionContract>;
}

const contracts = ref<CollectionContract[]>([]);
const poolContract = ref<CollectionContract>();
const marketplaceContractAddress = ref<IContract['marketplace']>('');

const initializeContracts = async () => {
	const { data } = (await apiService.getContracts()) as { data: IContract };
	contracts.value = data.collectionContracts;
	poolContract.value = data.collectionContracts[0];
	marketplaceContractAddress.value = data.marketplace;
};

export function useContracts(): IUseContractReturn {
	return {
		initializeContracts,
		contracts,
		marketplaceContractAddress,
		poolContract,
	};
}
